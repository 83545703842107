import {GridItem, Heading, SimpleGrid, useTheme, VStack} from '@chakra-ui/react'
import ServiceCard from '../Molecules/ServiceCard'
import {ServiceGroup} from '../../dataModels/Service'
import {useIsMobile} from '../../hooks/useIsMobile'

interface Props {
  title?: string
  serviceGroups?: ServiceGroup[]
}

const ServiceList = ({title, serviceGroups}: Props) => {
  const maxDescLength: number = 65

  const theme = useTheme()
  const isMobile = useIsMobile()


  return (
      <VStack w='full' spacing={4}>
        {title && (
            <Heading variant='big' color={theme.colors.text.basic} w='full'>
              {title}
            </Heading>
        )}
        <SimpleGrid columns={[2, null, 4]} spacing={isMobile ? 4 : 6} w='full'>
          {serviceGroups &&
              serviceGroups.map((serviceGroup, index) => (
                  <GridItem key={index}>
                    <ServiceCard
                        key={serviceGroup.title}
                        serviceGroup={serviceGroup}
                        maxDescLength={maxDescLength}
                    />
                  </GridItem>
              ))}
        </SimpleGrid>
      </VStack>
  )
}

export default ServiceList
