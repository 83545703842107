export enum STORY_TYPE {
  FANTASY = 'fantasy',
  HORROR = 'horror',
  THRILLER = 'thriller',
  SCIENCE_FICTION = 'sf',
  EPIC = 'epic',
  COMIC = 'comic',
  LYRICAL = 'lyrical',
  ROMANTIC = 'romantic',
  REALISTIC = 'realistic',
}

export enum SUMMARY_TYPE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  DEFAULT = 'default',
}
