import {GPT_MODEL} from '@/constants/gpt3'

export class GptConfig {
  constructor(
    public maxOutputTokens: number,
    public temperature: number,
    public topP: number,
    public frequency_penalty: number = 0,
    public presence_penalty: number = 0,
    public model?: GPT_MODEL
  ) {}

  toString() {
    return `[maxToken: ${this.maxOutputTokens},temperature: ${this.temperature}, topP: ${this.topP}, freqPenality: ${this.frequency_penalty}, presPenality: ${this.presence_penalty}]`
  }

  copy(partial: Partial<GptConfig>) {
    return new GptConfig(
      partial.maxOutputTokens ?? this.maxOutputTokens,
      partial.temperature ?? this.temperature,
      partial.topP ?? this.topP,
      partial.frequency_penalty ?? this.frequency_penalty,
      partial.presence_penalty ?? this.presence_penalty,
      partial.model ?? this.model
    )
  }
}

export enum SKILL_TYPE {
  COMPLETION = 'completion',
  EXTRACTION = 'extraction',
  CREATIVE_WRITING = 'creative-writing',
  FIX = 'fix',
  TRANSLATION = 'translation',
  FREE = 'free',
}
