export const GPT_3_TOKEN_LENGTH = 4

export const GPT_3_FILTER_ENGINE = 'content-filter-alpha'
export const GPT_3_TOXIC_THRESHOLD = -0.355

export const GPT_3_ERR_TOO_LONG = 'too-long'
export const GPT_3_ERR_NO_USER = 'no-user'
export const GPT_3_ERR_NO_USAGE = 'no-usage'

export enum GPT_MODEL {
  GPT_3_DAVINCI_MODEL_002 = 'text-davinci-002',
  GPT_3_DAVINCI_MODEL_003 = 'text-davinci-003',
  GPT_3_TURBO = 'gpt-3.5-turbo',
  GPT_3_TURBO_16K = 'gpt-3.5-turbo-16k',
  GPT_3_CURIE_MODEL = 'text-curie-001',
  GPT_4_TURBO = 'gpt-4-turbo',
}
