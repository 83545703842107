import {AspectRatio, Box, Flex, Heading, Text, useTheme} from '@chakra-ui/react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {motion, useAnimation} from 'framer-motion'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import {ServiceGroup} from '../../dataModels/Service'
import {track} from '../../utils/eventTracking/track'
import {getServiceColor, getServiceColorGradient, getServiceIcon} from '../../utils/sevices'
import {useIsMobile} from '../../hooks/useIsMobile'

interface Props {
  serviceGroup: ServiceGroup
  maxDescLength: number
}

const ServiceCard = ({serviceGroup, maxDescLength}: Props) => {
  const theme = useTheme()
  const controls = useAnimation()
  const {t} = useTranslation()
  const isMobile = useIsMobile()

  let contentColor = theme.colors.text.basic
  if (serviceGroup.disabled) {
    contentColor = theme.colors.text.grey
  }

  const trackClick = () => {
    let message: string

    if (serviceGroup.disabled) {
      message = `feature-click-disabled-${serviceGroup.title}-card`
    } else {
      message = `feature-click-${serviceGroup.title}-card`
    }

    track(message)
  }

  const card = () => (
    <AspectRatio
      onClick={trackClick}
      ratio={isMobile ? 4 / 3 : 16 / 9}
      m={0}
      background={
        serviceGroup.disabled
          ? theme.colors.layers.appLevel1
          : getServiceColorGradient(serviceGroup.color)
      }
      borderRadius='big'
      overflow='hidden'
      cursor={serviceGroup.disabled ? 'not-allowed' : 'pointer'}
      onMouseEnter={() => {
        !serviceGroup.disabled && controls.start('hover')
      }}
      onMouseLeave={() => {
        !serviceGroup.disabled && controls.start('rest')
      }}>
      <Box m={0} position='relative'>
        <Box
          m={0}
          position='relative'
          h='full'
          w='full'
          backgroundImage={`url(${serviceGroup.image.src})`}
          backgroundSize='auto 100%'
          backgroundPosition='right'
        />
        <Box
          position='absolute'
          m={0}
          w='full'
          h='full'
          _hover={{textShadow: ' 0px 0px 30px rgba(0, 0, 0, 0.4)'}}>
          <motion.div
            initial='rest'
            animate={controls}
            variants={{
              rest: {y: 0, opacity: 1, transition: {duration: 0.25}},
              hover: {y: '-100%', opacity: 0, transition: {duration: 0.3}},
            }}
            style={{height: '100%'}}>
            <Flex w='full' h='full' flexDir='column' p={5} m={0} justify='space-between'>
              <Flex
                left={10}
                top={10}
                justifyContent='center'
                placeItems='center'
                backgroundColor={theme.colors.brand.white}
                w={10}
                h={10}
                borderRadius='full'
                color={getServiceColor(serviceGroup.color)}>
                <FontAwesomeIcon icon={getServiceIcon(serviceGroup.icon)} size='1x' />
              </Flex>
              <Flex justifyContent='start' left={3} right={3} bottom={15}>
                <Heading variant='small' color={contentColor}>
                  {t(`${serviceGroup.title}`)}
                </Heading>
              </Flex>
            </Flex>
          </motion.div>
          <motion.div
            initial='rest'
            animate={controls}
            style={{height: '100%'}}
            variants={{
              rest: {y: '100%', opacity: 0, transition: {duration: 0.3}},
              hover: {y: '-100%', opacity: 1, transition: {duration: 0.25}},
            }}>
            <Flex w='70%' h='full' align='flex-end' px={5}>
              <Text variant='smallBold' color={contentColor} mb={5}>
                {t(`${serviceGroup.description}`)}
              </Text>
            </Flex>
          </motion.div>
        </Box>
      </Box>
    </AspectRatio>
  )

  return (
    <>
      {!serviceGroup.disabled ? (
        <Link href={serviceGroup.href} passHref>
          {card()}
        </Link>
      ) : (
        <>{card()}</>
      )}
    </>
  )
}

export default ServiceCard
