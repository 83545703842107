import {
  MetaTagsInput,
  ProductDescriptionInput,
  SingleInput,
  SingleInputWithNElements,
} from '@/Models/SkillInputs'
import {z} from 'zod'
import {ServiceGroup, SkillDescription} from '../dataModels/Service'
import {SKILL_TYPE} from '../dataModels/SkillConfig'
import copyrightImage from '../public/assets/copywriting-for-products-tool-cedille-ai.png'
import rewritingImage from '../public/assets/rewriting-tool-cedille-ai.png'
import summarizeImage from '../public/assets/summarize-a-text-tool-Cedille-ai.png'
import writeStoryImage from '../public/assets/write-a-story-tool-cedille-ai.png'
import {ServiceColor, ServiceIcon} from '../utils/sevices'
import {SUMMARY_TYPE} from './options'
import {
  API_ROUTE_SKILL_BULLET_POINTS_EXTRACTION,
  API_ROUTE_SKILL_EXTRACT_SEO_OPTIMIZED_TEXT,
  API_ROUTE_SKILL_FIX_TEXT,
  API_ROUTE_SKILL_FORMAL_REWRITING,
  API_ROUTE_SKILL_INFORMAL_REWRITING,
  API_ROUTE_SKILL_KEYWORDS_EXTRACTION,
  API_ROUTE_SKILL_META_TAGS,
  API_ROUTE_SKILL_PARAPHRASING,
  API_ROUTE_SKILL_PRODUCT_DESCRIPTION,
  API_ROUTE_SKILL_STORY_WRITING,
  API_ROUTE_SKILL_SUMMARIZATION,
  API_ROUTE_SKILL_WRITE_FREELY,
  ROUTE_SERVICE_GROUP_COPYWRITING,
  ROUTE_SERVICE_GROUP_FIX,
  ROUTE_SERVICE_GROUP_REWRITING,
  ROUTE_SERVICE_GROUP_SUMMARIZATION,
  ROUTE_SKILL_BULLET_POINTS_EXTRACTION,
  ROUTE_SKILL_FIX_TEXT,
  ROUTE_SKILL_FORMAL_REWRITING,
  ROUTE_SKILL_INFORMAL_REWRITING,
  ROUTE_SKILL_KEYWORDS_EXTRACTION,
  ROUTE_SKILL_META_TAGS,
  ROUTE_SKILL_PARAPHRASING,
  ROUTE_SKILL_PRODUCT_DESCRIPTION,
  ROUTE_SKILL_SEO_OPTIMIZED_TEXT,
  ROUTE_SKILL_STORY_WRITING,
  ROUTE_SKILL_SUMMARIZATION,
} from './routes'

export const SKILL_SUMMARIZATION = new SkillDescription(
  API_ROUTE_SKILL_SUMMARIZATION,
  'skill:skill_summarization',
  ROUTE_SKILL_SUMMARIZATION,
  false,
  'summarization',
  SKILL_TYPE.COMPLETION,
  'Summarize your texts, interviews, papers, essays'
)

export const SKILL_STORY_WRITING = new SkillDescription(
  API_ROUTE_SKILL_STORY_WRITING,
  'skill:skill_story_writing',
  ROUTE_SKILL_STORY_WRITING,
  false,
  'story-writing',
  SKILL_TYPE.COMPLETION,
  'Continue your stories'
)

export const SKILL_BULLET_POINTS_EXTRACTION = new SkillDescription(
  API_ROUTE_SKILL_BULLET_POINTS_EXTRACTION,
  'skill:skill_bullet_points_extraction',
  ROUTE_SKILL_BULLET_POINTS_EXTRACTION,
  false,
  'bullet-points-extraction',
  SKILL_TYPE.EXTRACTION,
  'Extract bullet points from your texts, interviews, papers, essays'
)

export const SKILL_KEYWORDS_EXTRACTION = new SkillDescription(
  API_ROUTE_SKILL_KEYWORDS_EXTRACTION,
  'skill:skill_keywords_extraction',
  ROUTE_SKILL_KEYWORDS_EXTRACTION,
  false,
  'keywords-extraction',
  SKILL_TYPE.EXTRACTION,
  'Extract keywords from your texts, interviews, papers, essays'
)

export const SKILL_FORMAL_REWRITING = new SkillDescription(
  API_ROUTE_SKILL_FORMAL_REWRITING,
  'skill:skill_formal_rewriting',
  ROUTE_SKILL_FORMAL_REWRITING,
  false,
  'formal-rewriting',
  SKILL_TYPE.COMPLETION,
  'Rewrite formally your texts, interviews, papers, essays'
)

export const SKILL_INFORMAL_REWRITING = new SkillDescription(
  API_ROUTE_SKILL_INFORMAL_REWRITING,
  'skill:skill_informal_rewriting',
  ROUTE_SKILL_INFORMAL_REWRITING,
  false,
  'informal-rewriting',
  SKILL_TYPE.COMPLETION,
  'Rewrite informally your texts, interviews, papers, essays'
)

export const SKILL_PARAPHRASING = new SkillDescription(
  API_ROUTE_SKILL_PARAPHRASING,
  'skill:skill_paraphrasing',
  ROUTE_SKILL_PARAPHRASING,
  false,
  'paraphrasing',
  SKILL_TYPE.COMPLETION,
  'paraphrase your texts, interviews, papers, essays'
)

export const SKILL_PRODUCT_DESCRIPTION = new SkillDescription(
  API_ROUTE_SKILL_PRODUCT_DESCRIPTION,
  'skill:skill_product_description',
  ROUTE_SKILL_PRODUCT_DESCRIPTION,
  false,
  'product-description',
  SKILL_TYPE.CREATIVE_WRITING,
  'Create a description for your porduct'
)

export const SKILL_META_TAGS = new SkillDescription(
  API_ROUTE_SKILL_META_TAGS,
  'skill:skill_meta_tags',
  ROUTE_SKILL_META_TAGS,
  false,
  'meta-tags',
  SKILL_TYPE.CREATIVE_WRITING,
  'Create Meta tags for your porduct'
)

export const SKILL_FIX_TEXT = new SkillDescription(
  API_ROUTE_SKILL_FIX_TEXT,
  'skill:skill_fix_text',
  ROUTE_SKILL_FIX_TEXT,
  false,
  'fix-text',
  SKILL_TYPE.FIX,
  'Fix the mistakes in your texts, interviews, papers, essays'
)

export const SKILL_WRITE_FREELY = new SkillDescription(
  API_ROUTE_SKILL_WRITE_FREELY,
  'skill:skill_write_freely',
  ROUTE_SKILL_FIX_TEXT,
  false,
  'write-freely',
  SKILL_TYPE.FREE,
  'Write freely'
)

export const SKILL_EXTRACT_SEO_OPTIMIZED_TEXT = new SkillDescription(
  API_ROUTE_SKILL_EXTRACT_SEO_OPTIMIZED_TEXT,
  'skill:skill_extract_seo_optimized_text',
  ROUTE_SKILL_SEO_OPTIMIZED_TEXT,
  false,
  'extract-seo-optimized-text',
  SKILL_TYPE.CREATIVE_WRITING,
  'Extract SEO Optimized text'
)

export const SKILL_TRANSLATION = new SkillDescription(
  'no endpoint yet',
  '',
  'no page yet',
  true,
  'translation',
  SKILL_TYPE.TRANSLATION,
  'Translate your texts, interviews, papers, essays'
)

type addPrefix<TKey, TPrefix extends string> = TKey extends string ? `${TPrefix}${TKey}` : never

type removePrefix<TPrefixedKey, TPrefix extends string> = TPrefixedKey extends addPrefix<
  infer TKey,
  TPrefix
>
  ? TKey
  : ''

export const removePrefix = <P extends string, WP extends Record<addPrefix<string, P>, any>>(
  map: WP,
  prefix: P
): {[k in removePrefix<keyof WP, P>]: WP[addPrefix<k, P>]} => {
  return Object.entries(map).reduce(
    (acc, [k, v]) => ({...acc, [k.substring(prefix.length)]: v}),
    {} as {[k in removePrefix<keyof WP, P>]: WP[addPrefix<k, P>]}
  )
}
export const lowerCaseKeys = <O extends Record<string, any>>(
  o: O
): {[k in Lowercase<keyof O extends string ? keyof O : never>]: O[Uppercase<k>]} =>
  Object.entries(o).reduce(
    (acc, [k, v]) => ({...acc, [k.toLocaleLowerCase()]: v}),
    {} as {[k in Lowercase<keyof O extends string ? keyof O : never>]: O[Uppercase<k>]}
  )

export const AllSkills = lowerCaseKeys(
  removePrefix(
    {
      SKILL_SUMMARIZATION,
      SKILL_STORY_WRITING,
      SKILL_BULLET_POINTS_EXTRACTION,
      SKILL_KEYWORDS_EXTRACTION,
      SKILL_FORMAL_REWRITING,
      SKILL_INFORMAL_REWRITING,
      SKILL_PARAPHRASING,
      SKILL_PRODUCT_DESCRIPTION,
      SKILL_META_TAGS,
      SKILL_TRANSLATION,
      SKILL_FIX_TEXT,
      SKILL_WRITE_FREELY,
      SKILL_EXTRACT_SEO_OPTIMIZED_TEXT,
    },
    'SKILL_'
  )
)

export const inputByService = (service: string) => {
  switch (service as keyof typeof AllSkills) {
    case 'summarization':
      return SingleInput().extend({summaryType: z.nativeEnum(SUMMARY_TYPE)})

    case 'bullet_points_extraction':
    case 'keywords_extraction':
      return SingleInputWithNElements().extend({numberOfElements: z.number().min(1)})
    case 'product_description':
      return ProductDescriptionInput
    case 'meta_tags':
      return MetaTagsInput

    case 'paraphrasing':
    case 'formal_rewriting':
    case 'informal_rewriting':
    case 'story_writing':
    case 'translation':
    case 'fix_text':
    case 'write_freely':
    default:
      return SingleInput()
  }
}

export const SUMMARIZATION_SKILLS = new ServiceGroup(
  [SKILL_SUMMARIZATION, SKILL_BULLET_POINTS_EXTRACTION, SKILL_KEYWORDS_EXTRACTION],
  ServiceIcon.BARS_FILTER,
  ServiceColor.RED,
  false,
  'service-group:service_summary_title',
  'service-group:service_summary_description',
  ROUTE_SERVICE_GROUP_SUMMARIZATION,
  summarizeImage
)

export const REDACTION_SKILLS = new ServiceGroup(
  [SKILL_STORY_WRITING],
  ServiceIcon.BOOK,
  ServiceColor.PURPLE,
  false,
  'service-group:service_redaction_title',
  'service-group:service_redaction_description',
  ROUTE_SKILL_STORY_WRITING,
  writeStoryImage
)

export const COPYWRITING_FOR_PRODUCTS_SKILLS = new ServiceGroup(
  [SKILL_PRODUCT_DESCRIPTION, SKILL_META_TAGS, SKILL_EXTRACT_SEO_OPTIMIZED_TEXT],
  ServiceIcon.BOX,
  ServiceColor.BLUE,
  false,
  'service-group:service_copywriting_title',
  'service-group:service_copywriting_description',
  ROUTE_SERVICE_GROUP_COPYWRITING,
  copyrightImage
)

export const REWRITING_SKILLS = new ServiceGroup(
  [SKILL_PARAPHRASING, SKILL_FORMAL_REWRITING, SKILL_INFORMAL_REWRITING],
  ServiceIcon.PARAGRAPH,
  ServiceColor.ORANGE,
  false,
  'service-group:service_rewriting_title',
  'service-group:service_rewriting_description',
  ROUTE_SERVICE_GROUP_REWRITING,
  rewritingImage
)
export const FIX_SKILLS = new ServiceGroup(
  [SKILL_FIX_TEXT],
  ServiceIcon.FIX,
  ServiceColor.BLUE,
  false,
  'service-group:service_fix-text_title',
  'service-group:service_fix-text_description',
  ROUTE_SERVICE_GROUP_FIX,
  rewritingImage
)

export const ALL_SKILLS = [
  SUMMARIZATION_SKILLS,
  REDACTION_SKILLS,
  COPYWRITING_FOR_PRODUCTS_SKILLS,
  REWRITING_SKILLS,
  FIX_SKILLS,
]

export const getFourRandomSkills = () => {
  return ALL_SKILLS.sort(() => 0.5 - Math.random()).slice(0, 4)
}
