import {StaticImageData} from 'next/image'
import {SUPPORTED_LANGUAGES} from '../constants/languages'
import {ServiceColor, ServiceIcon} from '../utils/sevices'
import {SKILL_TYPE} from './SkillConfig'

export enum SERVICE_TYPE {
  model = 'MODEL',
  skill = 'SKILL',
}

export class ServiceGroup {
  constructor(
    public services: Service[],
    public icon: ServiceIcon,
    public color: ServiceColor,
    public disabled: boolean,
    public title: string,
    public description: string,
    public href: string,
    public image: StaticImageData
  ) {}
}

export abstract class Service {
  protected constructor(
    public type: SERVICE_TYPE,
    public endpoint: string,
    public title: string,
    public href: string,
    public disabled: boolean
  ) {}

  abstract serviceType(): SERVICE_TYPE
}
export class SkillDescription extends Service {
  constructor(
    endpoint: string,
    title: string,
    href: string,
    disabled: boolean,
    readonly skillName: string,
    readonly skillType: SKILL_TYPE,
    readonly description: string
  ) {
    super(SERVICE_TYPE.skill, endpoint, title, href, disabled)
  }

  serviceType(): SERVICE_TYPE {
    return SERVICE_TYPE.skill
  }
}

export class Model extends Service {
  public language

  constructor(
    endpoint: string,
    title: string,
    href: string,
    language: SUPPORTED_LANGUAGES,
    disabled: boolean,
    public modelName: string
  ) {
    super(SERVICE_TYPE.model, endpoint, title, href, disabled)
    this.language = language
    this.modelName = modelName
  }

  serviceType(): SERVICE_TYPE {
    return SERVICE_TYPE.model
  }
}
