import {
  faBarsFilter,
  faBook,
  faBox,
  faChartNetwork,
  faEnvelopeDot,
  faEnvelopeOpenText,
  faFileLines,
  faGlobe,
  faLanguage,
  faList,
  faNewspaper,
  faNotes,
  faParagraph,
  faPenLine,
  faSparkles,
  faSurprise,
} from '@fortawesome/pro-regular-svg-icons'
import {faPlus, faTag, faText} from '@fortawesome/pro-solid-svg-icons'
import {faHexagonCheck} from '@fortawesome/pro-light-svg-icons'

export enum ServiceIcon {
  TEXT = 'text',
  GLOBE = 'globe',
  NEWS = 'news',
  LANGUAGE = 'language',
  EMOTION = 'emotion',
  BOOK = 'book',
  STARS = 'stars',
  PEN = 'pen',
  PLUS = 'plus',
  FILE = 'file',
  LIST = 'list',
  PARAGRAPH = 'paragraph',
  FIX = 'fix',
  CHART_NETWORK = 'chart-network',
  NOTES = 'notes',
  ENVELOPE_TEXT = 'envelope-open-text',
  ENVELOPE_DOT = 'envolope-dot',
  TAG = 'tag',
  BOX = 'box',
  BARS_FILTER = 'bar-filter',
}

export enum ServiceColor {
  RED = 'red',
  ORANGE = 'orange',
  PURPLE = 'purple',
  BLUE = 'blue',
  GREEN = 'green',
}

export const getServiceColorGradient = (color: ServiceColor) => {
  switch (color) {
    case ServiceColor.BLUE:
      return 'services.gradientBlue'
    case ServiceColor.RED:
      return 'services.gradientRed'
    case ServiceColor.ORANGE:
      return 'services.gradientOrange'
    case ServiceColor.PURPLE:
      return 'services.gradientPurple'
    case ServiceColor.GREEN:
      return 'services.gradientGreen'
  }
}

export const getServiceColor = (color: ServiceColor) => {
  switch (color) {
    case ServiceColor.BLUE:
      return 'services.blue'
    case ServiceColor.RED:
      return 'services.red'
    case ServiceColor.ORANGE:
      return 'services.orange'
    case ServiceColor.PURPLE:
      return 'services.purple'
    case ServiceColor.GREEN:
      return 'services.green'
  }
}

export const getServiceIcon = (icon: ServiceIcon) => {
  switch (icon) {
    case ServiceIcon.TEXT:
      return faText
    case ServiceIcon.GLOBE:
      return faGlobe
    case ServiceIcon.NEWS:
      return faNewspaper
    case ServiceIcon.LANGUAGE:
      return faLanguage
    case ServiceIcon.EMOTION:
      return faSurprise
    case ServiceIcon.BOOK:
      return faBook
    case ServiceIcon.STARS:
      return faSparkles
    case ServiceIcon.PEN:
      return faPenLine
    case ServiceIcon.PLUS:
      return faPlus
    case ServiceIcon.FILE:
      return faFileLines
    case ServiceIcon.PARAGRAPH:
      return faParagraph
    case ServiceIcon.FIX:
      return faHexagonCheck
    case ServiceIcon.LIST:
      return faList
    case ServiceIcon.CHART_NETWORK:
      return faChartNetwork
    case ServiceIcon.NOTES:
      return faNotes
    case ServiceIcon.ENVELOPE_TEXT:
      return faEnvelopeOpenText
    case ServiceIcon.ENVELOPE_DOT:
      return faEnvelopeDot
    case ServiceIcon.TAG:
      return faTag
    case ServiceIcon.BOX:
      return faBox
    case ServiceIcon.BARS_FILTER:
      return faBarsFilter
  }
}
