import {z} from 'zod'
import {GPT_3_TOKEN_LENGTH} from '../constants/gpt3'
import {SUPPORTED_LANGUAGES} from '../constants/languages'

const Basis = z.object({
  forceLang: z.nativeEnum(SUPPORTED_LANGUAGES).optional(),
})

export const SingleInput = (maxChars: number = 3000) =>
  Basis.extend({
    input: maxChars === -1 ? z.string() : z.string().max(maxChars * GPT_3_TOKEN_LENGTH),
  })

export const SingleInputWithNElements = (maxChars: number = 3000) => {
  return SingleInput(maxChars).merge(z.object({numberOfElements: z.coerce.number().min(1)}))
}
export type SingleInput = z.infer<ReturnType<typeof SingleInput>>
export type SingleInputWithNElements = z.infer<ReturnType<typeof SingleInputWithNElements>>

export const MetaTagsInput = Basis.extend({
  websiteName: z.string(),
  productOrServiceName: z.string(),
  productOrServiceType: z.string(),
  productOrServiceCharacteristic: z.string(),
  textLanguage: z.nativeEnum(SUPPORTED_LANGUAGES),
})
export type MetaTagsInput = z.infer<typeof MetaTagsInput>

export const ProductDescriptionInput = Basis.extend({
  productName: z.string().max(100),
  productType: z.string().max(100),
  productCharacteristic: z.string().max(1000),
  productAudience: z.string().max(150).optional(),
  textLanguage: z.nativeEnum(SUPPORTED_LANGUAGES),
})

export type ProductDescriptionInput = z.infer<typeof ProductDescriptionInput>

export const PartnerInput = z.union([
  SingleInput(),
  SingleInputWithNElements(),
  MetaTagsInput,
  ProductDescriptionInput,
])

export type PartnerInput = z.infer<typeof PartnerInput>
